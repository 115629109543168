import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Modal from "@material-ui/core/Modal";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

import Header from "../../components/header";
import { axiosConfig } from "../../utils/axiosConfig";
import Presidents from "./PresidentsTable";
import {
  SettingsWrapper,
  StyledButton,
  ModalBody,
  ButtonsWrapper,
} from "./styles";
import SnackbarMessage from "../../components/Snackbar";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    marginRight: 24
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function AdminSettings() {
  const classes = useStyles();
  const date = new Date();
  const year = date.getFullYear();
  const [expanded, setExpanded] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState("");
  const [presidents, setPresidents] = React.useState([]);
  const [variant, setVariant] = React.useState("success");
  const [message, setMessage] = React.useState("");
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [age, setAge] = React.useState('');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const startNewYear = () => {
    axiosConfig.post(`/settings/startNewYear`).then((res) => {
      if (res.status === 200) {
        setOpen(false);
        setVariant("success");
        setMessage("New year program has been started!");
        setSnackOpen(true);
      }
    });
  };

  async function generateReport() {
    await axiosConfig
      .post(`/settings/startYearlyReportGeneration/${age}`)
      .then((res) => {
        if (res.status === 200) {
          setDialogOpen(false);
          setMessage(res.data.message);
          setSnackOpen(true);
          setVariant("success");
        }
      })
      .catch((e) => {
        setDialogOpen(false);
        setMessage(e.data.message);
        setVariant("error");
        setSnackOpen(true);
      });
  }

  React.useEffect(() => {
    setLoading(true);
    axiosConfig.get("/settings/namesOnCertificate").then(({ data }) => {
      setPresidents(data);
    });
    setLoading(false);
  }, []);

  async function editPresident(data, oldData) {
    setLoading(true);
    await axiosConfig
      .post("/settings/namesOnCertificate", { ...data, title: "President" })
      .then((res) => {
        if (res.status === 200) {
          setVariant("success");
          setMessage("Success");
          setSnackOpen(true);
          const index = presidents.indexOf(oldData);
          const shallow = presidents.slice();
          shallow[index] = data;
          setPresidents(shallow);
          setLoading(false);
        }
      })
      .catch((e) => {
        setMessage(e.data.message);
        setVariant("error");
        setSnackOpen(true);
        setLoading(false);
      });
  }

  const deletePresident = async (data) => {
    setLoading(true);
    await axiosConfig
      .delete(`/settings/namesOnCertificate/${data.year}`)
      .then((res) => {
        if (res.status === 200) {
          setMessage("Success");
          axiosConfig.get("/settings/namesOnCertificate").then(({ data }) => {
            setPresidents(data);
            setLoading(false);
          });
        }
      });
  };

  const addPresident = async (data) => {
    setLoading(true);
    await axiosConfig
      .put(`/settings/namesOnCertificate`, {
        ...data,
        title: "President",
      })
      .then((res) => {
        if (res.status === 200) {
          setVariant("success");
          setMessage("Success");
          setSnackOpen(true);
          axiosConfig.get("/settings/namesOnCertificate").then(({ data }) => {
            setPresidents(data);
            setLoading(false);
          });
        }
      })
      .catch((e) => {
        setMessage(e.data.message);
        setVariant("error");
        setSnackOpen(true);
        setLoading(false);
      });
  };

  return (
    <>
      <Header />
      <SettingsWrapper>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary>
            <p>New Year Program</p>
          </AccordionSummary>
          <AccordionDetails>
            <StyledButton onClick={() => setOpen(true)}>
              Start New Year Program
            </StyledButton>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary>
            <p>Presidents</p>
          </AccordionSummary>
          <AccordionDetails>
            <Presidents
              presidents={presidents}
              editPresident={editPresident}
              deletePresident={deletePresident}
              addPresident={addPresident}
              loading={loading}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary>
            <p>Yearly Report Generation</p>
          </AccordionSummary>
          <AccordionDetails>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Year</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={age}
              label="Age"
              onChange={(event) => setAge(event.target.value)}
            >
              {[
                ...Array.from(
                  { length: new Date().getFullYear() - 2021 + 1 },
                  (_, i) => 2021 + i
                ),
                ...(year > new Date().getFullYear() ? [year] : []),
              ].map((y) => (
                <MenuItem key={y} value={y}>
                  {y}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
            <StyledButton onClick={() => setDialogOpen(true)} disabled={!age}>
              Run yearly report generation
            </StyledButton>
          </AccordionDetails>
        </Accordion>
      </SettingsWrapper>
      <Modal open={open} onClose={() => setOpen(false)}>
        <div style={{ position: "absolute", left: "50%", outline: "none" }}>
          <ModalBody>
            <p>Do you want to proceed with yearly report generation?</p>
            <ButtonsWrapper>
              <StyledButton onClick={startNewYear}>
                Confirm
              </StyledButton>
              <StyledButton onClick={() => setOpen(false)}>Cancel</StyledButton>
            </ButtonsWrapper>
          </ModalBody>
        </div>
      </Modal>
      <Modal open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <div style={{ position: "absolute", left: "50%", outline: "none" }}>
          <ModalBody>
            <p>Do you want to proceed with yearly report generation?</p>
            <ButtonsWrapper>
              <StyledButton onClick={generateReport}>
                Confirm
              </StyledButton>
              <StyledButton onClick={() => setDialogOpen(false)}>Cancel</StyledButton>
            </ButtonsWrapper>
          </ModalBody>
        </div>
      </Modal>
      <SnackbarMessage
        variant={variant}
        message={message}
        onClose={() => {
          setSnackOpen(false);
        }}
        open={snackOpen}
      />
    </>
  );
}

export default AdminSettings;
